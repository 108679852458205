import _ from "lodash";
import IndexLayout from "../components/IndexLayout3";
import { getCollectionCache, collectionMap } from "../services/collections";
import { getByImageId, getBySvgId } from "../services/images-service";
import {
    getPromptByImageId,
    getPromptBySvgId,
    getRandomCollectionImages,
} from "../services/supabase";
import { generateCollectionUrl } from "../services/urls";

export default function Home(props) {
    return <IndexLayout {...props} />;
}

export async function getServerSideProps(context) {
    if (context.query.redirect && context.query.redirect?.startsWith("/")) {
        return {
            redirect: {
                destination: context.query.redirect,
                permanent: false,
            },
        };
    }
    let initialItem = null;
    const result = await getRandomCollectionImages(20);
    const imageId = context.query?.slug?.[0];
    if (imageId && imageId.endsWith(".php")) {
        return {
            notFound: true,
        }
    }
    if (imageId) {
        const libraryImage = await getByImageId(imageId);
        if (libraryImage) {
            return {
                redirect: {
                    destination: `/browse/${imageId}`,
                    permanent: false,
                },
            };
        }
        const svgImage = await getBySvgId(imageId);
        if (svgImage) {
            return {
                redirect: {
                    destination: `/browse/${imageId}`,
                    permanent: false,
                },
            };
        }
        const collections = await getCollectionCache(
            require("fs"),
            require("path")
        );
        const collectionImage = collections[imageId];
        if (collectionImage) {
            return {
                redirect: {
                    destination: generateCollectionUrl(
                        _.kebabCase(
                            collectionMap[collectionImage.collection_name]
                        ),
                        {
                            ...collectionImage,
                            description: collectionImage.prompt,
                        }
                    ),
                    permanent: false,
                },
            };
        }
        let prompt = await getPromptByImageId(imageId);
        console.log("prompt", prompt);
        if (!prompt) {
            prompt = await getPromptBySvgId(imageId);
            console.log("prompt", prompt);
        }
        if (prompt) {
            initialItem = {
                identifier: prompt.identifier,
                image: prompt.svg,
                description: prompt.prompt,
                url: `https://imagedelivery.net/xE-VtsYZUS2Y8MtLMcbXAg/${prompt.identifier}/orig`,
            };
        }
        if (!initialItem) {
            return {
                notFound: true,
            };
        }
    }
    context.res.setHeader(
        "Cache-Control",
        "public, s-maxage=1800, stale-while-revalidate=3600"
    );
    return {
        props: {
            initialImages: result.slice(0, 20),
            initialItem,
        },
    };
}
